import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core';
import { Magic } from 'magic-sdk';
import TextField from '@material-ui/core/TextField';
import { MAGIC_API_KEY } from '../env';
import { Dispatch } from 'redux';
import swapMagicToken from '../utils/login';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Sentry from '@sentry/browser';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 400,
  },
  email: {
    width: 350,
    marginBottom: 20,
  },
}));

const magic = new Magic(MAGIC_API_KEY);

async function magicLogin(
  email: string,
  dispatch: Dispatch<any>,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  setLoading: Function,
) {
  if (!email) return;

  try {
    setLoading(true);
    const magicToken = await magic.auth.loginWithMagicLink({ email });

    if (magicToken != null) {
      swapMagicToken(magicToken, dispatch, setError);
    } else {
      setError('Sorry! Something went wrong during login, please try again.');
    }
  } catch (e) {
    setError('Sorry! Something went wrong during login, please try again.');
    Sentry.captureException(e);
  } finally {
    setLoading(false);
  }
}

export default function LoginPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <h2>
            Sign in to Risk Insights
            {process.env.REACT_APP_RA_ENV && ` [${process.env.REACT_APP_RA_ENV}]`}
          </h2>
          <p>A login link will be sent to your email address</p>
          <TextField
            id="filled-basic"
            className={classes.email}
            label="Email"
            variant="filled"
            value={email}
            error={!!error}
            helperText={error ? error : undefined}
            onChange={(event) => setEmail(event.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                magicLogin(email, dispatch, setError, setLoading);
                ev.preventDefault();
              }
            }}
          />
          <br />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => magicLogin(email, dispatch, setError, setLoading)}
          >
            Log In
            {isLoading ? (
              <CircularProgress style={{ marginLeft: '10px', height: '25px', width: '25px' }} />
            ) : null}
          </Button>
          <h4>
            Do you want to gain access to the WorldCover CropAssure products? Email us at{' '}
            <a href="mailto:sales@worldcovr.com">sales@worldcovr.com</a>
          </h4>
        </Paper>
      </Grid>
    </Grid>
  );
}
