// Setup Mock data
export const cddMockData = [...Array(19).keys()].map((key) => {
  const x = key + 2001;
  let y = getRandomArbitrary(15, 35);
  if (x === 2013) {
    y = 62;
  } else if (x === 2019) {
    y = 51;
  }
  return { x, y };
});

export const crwMockData = [...Array(19).keys()].map((key) => {
  const x = key + 2001;
  let y = getRandomArbitrary(75, 115);
  if (x === 2009) {
    y = 174;
  } else if (x === 2005) {
    y = 126;
  }
  return { x, y };
});

export const fddMockData = [...Array(39).keys()].map((key) => {
  const x = key + 1981;
  let y = getRandomArbitrary(0, 2);
  if (x === 1989) {
    y = 5;
  } else if (x === 1990) {
    y = 15;
  } else if (x === 2011) {
    y = 5;
  } else if (x === 2018) {
    y = 7;
  } else if (x === 2019) {
    y = 10;
  }
  return { x, y };
});

export const cchMockData = [...Array(39).keys()].map((key) => {
  const x = key + 1981;
  let y = getRandomArbitrary(220, 350);
  if (x === 2003) {
    y = 190;
  } else if (x === 2015) {
    y = 182;
  }
  return { x, y };
});

type PayoutsMap = { [year: number]: number };
const payoutsMap: PayoutsMap = {
  2003: 3350000,
  2005: 1950000,
  2009: 5512500,
  2011: 4750000,
  2015: 1755000,
  2018: 4900750,
  2019: 6300000,
};

export const payoutMockData = [...Array(19).keys()].map((key) => {
  const year = key + 2001;
  const payout = year in payoutsMap ? payoutsMap[year] : 0;
  return { year, payout };
});

export function getRandomArbitrary(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function generateSeries(max: number, seriesLength: number) {
  var r = [];
  var currSum = 0;
  for (var i = 0; i < seriesLength - 1; i++) {
    r[i] = getRandomArbitrary(1, max - (seriesLength - i - 1) - currSum);
    currSum += r[i];
  }
  r[seriesLength - 1] = max - currSum;
  return r;
}
