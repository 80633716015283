import React, { useState } from 'react';
import { Hint, VerticalBarSeries, XAxis, FlexibleXYPlot, YAxis } from 'react-vis';
import '../../node_modules/react-vis/dist/style.css';

export type ChartData = { x: number; y: number }[];

type PayoutChartProps = {
  data: ChartData;
  topPadding?: number;
  yAxis?: string;
};

export default function PayoutChart(props: PayoutChartProps) {
  const { topPadding = 0, data, yAxis = '' } = props;

  const [hoveredPoint, setHoveredPoint] = useState<{
    x: number | string;
    y: number;
  }>();

  const yMin = 0;
  const yMax = Math.max(...data.map((e) => e.y)) + topPadding;
  return (
    <FlexibleXYPlot
      margin={{ left: 75 }}
      yDomain={[yMin, yMax]}
      xType="ordinal"
      colorType="literal"
    >
      <XAxis
        tickValues={[data[0], data[Math.floor(data.length / 2)], data[data.length - 1]].map(
          (record) => record.x,
        )}
      />
      <YAxis title={yAxis} />

      <VerticalBarSeries
        data={data}
        barWidth={0.6}
        color="#254B00"
        animation={false}
        onValueMouseOver={(event) => {
          setHoveredPoint({
            x: event.x,
            y: event.y,
          });
        }}
        onValueMouseOut={(event) => {
          setHoveredPoint(undefined);
        }}
      />

      {hoveredPoint && (
        <Hint value={hoveredPoint}>
          <div style={{ background: 'black', padding: '4px' }}>
            <h4>{hoveredPoint.x}</h4>
            <p>${hoveredPoint.y.toLocaleString()}</p>
          </div>
        </Hint>
      )}
    </FlexibleXYPlot>
  );
}
