import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { payoutMockData } from '../utils/mockData';

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
  payoutValue: {
    fontSize: 25,
  },
  totalPayoutValue: {
    fontSize: 30,
    color: '#254B00',
  },
});

function createData() {
  return payoutMockData.filter((pay) => pay.payout !== 0);
}

const rows = createData();

export default function PayoutTable() {
  const classes = useStyles();
  const totalPayout = payoutMockData.reduce((tot, pay) => tot + pay.payout, 0);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell align="right">Payout</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.year}>
              <TableCell component="th" scope="row">
                {row.year}
              </TableCell>
              <TableCell
                className={classes.payoutValue}
                align="right"
              >{`$${row.payout.toLocaleString()}`}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell className={classes.totalPayoutValue} component="th" scope="row">
              Total
            </TableCell>
            <TableCell
              className={classes.totalPayoutValue}
              align="right"
            >{`$${totalPayout.toLocaleString()}`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
