import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import nuts from './nuts.jpg';
import { Profile } from '../reducers/profile';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  media: {
    height: 200,
  },
  pos: {
    marginBottom: 12,
  },
});

type CustomerCardProps = {
  profile: Profile;
};

export default function CustomerCard(props: CustomerCardProps) {
  const classes = useStyles();
  const { name, anchorValue, site, period } = props.profile;
  const { lat, lng } = site;
  const { start, end } = period;
  const convertPeriod = (per: string) => moment(per, 'YYYY-MM-DD').format('MMM D');

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={nuts} title="Nuts" />
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Customer Profile
        </Typography>
        <Typography variant="h4" component="h2">
          {name}
        </Typography>
        <Typography variant="h5" component="p">
          Anchor Value: ${anchorValue.toLocaleString()}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          ({lat}, {lng})
        </Typography>
        <Typography variant="body1" component="p">
          Crop: Almond
          <br />
          Risk Period: {convertPeriod(start)} - {convertPeriod(end)}
        </Typography>
      </CardContent>
    </Card>
  );
}
