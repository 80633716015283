import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//import locktonLogo from './lockton-logo.svg';
import logo from './logo-icon.svg';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#121427',
    color: 'white',
    height: '65px',
    '& img': {
      marginRight: theme.spacing(1),
    },
  },
  iconWrapper: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  loginButton: {
    marginRight: theme.spacing(2),
  },
  flex: { display: 'flex' },
}));

type HeaderBarProps = {
  isAuthenticated: boolean;
  logout: () => void;
};

export function HeaderBar(props: HeaderBarProps) {
  const { isAuthenticated, logout } = props;
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.iconWrapper}>
          <img width={35} src={logo} alt="WorldCover" />
          <Typography variant="h5">Risk Insights</Typography>
        </div>

        {isAuthenticated && (
          <span className={classes.flex}>
            <Button className={classes.loginButton} onClick={logout} color="inherit">
              Log Out
            </Button>
          </span>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default HeaderBar;
