import React from 'react';
import { IndexDataPoint } from '../reducers/weather';
import '../../node_modules/react-vis/dist/style.css';

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
  RVTickFormat,
  LabelSeries,
  MarkSeries,
  LineSeriesPoint,
  MarkSeriesPoint,
} from 'react-vis';

type ImpactEventChartProps = {
  mockData?: LineSeriesPoint[];
  riskData?: IndexDataPoint[];
  criticalLevel: number;
  mildLevel: number;
  topPadding?: number;
  impactAboveThreshold?: boolean;
};
export default function ImpactEventChart(props: ImpactEventChartProps) {
  const {
    criticalLevel,
    mildLevel,
    mockData,
    riskData,
    topPadding = 20,
    impactAboveThreshold = true,
  } = props;
  let riskLineData: LineSeriesPoint[];
  if (mockData) {
    riskLineData = mockData;
  } else {
    riskLineData = riskData!.map((pt) => ({
      x: parseInt(pt.date.slice(0, 4), 10),
      y: pt.value,
    }));
  }
  const startYear = riskLineData[0].x;
  const dataRangeLength = riskLineData.length;
  const _xTickFormatValue = (v: number, i: any, scale: any, tickTotal: any) =>
    Number.isInteger(v) ? `${v}` : '';

  // const _yTickFormatValue = (v: any, i: any, scale: any, tickTotal: any) =>
  // `${scale.tickFormat(tickTotal, 's')(v)} Days`;

  const criticalLine = [...Array(dataRangeLength).keys()].map((key) => ({
    x: key + startYear,
    y: criticalLevel,
  }));
  const lowLine = [...Array(dataRangeLength).keys()].map((key) => ({
    x: key + startYear,
    y: mildLevel,
  }));

  const xMin = Math.min(...riskLineData.map((e) => e.x));
  const xMax = Math.max(...riskLineData.map((e) => e.x));
  const yMin = 0;
  const yMax = Math.max(...riskLineData.map((e) => e.y), criticalLevel) + topPadding;

  const impactEventData = riskLineData.reduce((evData: MarkSeriesPoint[], riskPoint) => {
    if (impactAboveThreshold === true && riskPoint.y >= mildLevel) {
      return [...evData, riskPoint];
    } else if (impactAboveThreshold === false && riskPoint.y <= mildLevel) {
      return [...evData, riskPoint];
    } else {
      return evData;
    }
  }, [] as MarkSeriesPoint[]);

  return (
    <XYPlot
      width={600}
      height={300}
      xType="linear"
      xDomain={[xMin, xMax]}
      yType="linear"
      yDomain={[yMin, yMax]}
      margin={{ top: 10, right: 10, left: 60, bottom: 40 }}
    >
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis
        tickValues={riskLineData.map((v) => v.x)}
        tickFormat={_xTickFormatValue as RVTickFormat}
      />
      <YAxis />
      <LineSeries color="#3BAE65" data={riskLineData} />
      <LineSeries
        data={criticalLine}
        color="#E84855"
        style={{
          strokeWidth: 3,
        }}
      />
      <LineSeries
        style={{
          strokeDasharray: '2, 8',
        }}
        color="#E84855"
        data={lowLine}
      />
      <MarkSeries
        data={impactEventData}
        stroke="#FCBF09"
        style={{
          fill: 'none',
          strokeWidth: 3,
        }}
      />
      <LabelSeries
        data={[
          { x: startYear, y: mildLevel, label: 'Mild' },
          { x: startYear, y: criticalLevel, label: 'Critical' },
        ]}
        style={{ fontSize: 10 }}
      />
    </XYPlot>
  );
}
