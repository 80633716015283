import React from 'react';
import moment from 'moment';
import { XAxis, FlexibleWidthXYPlot, YAxis, LineSeries, HorizontalGridLines } from 'react-vis';
import '../../node_modules/react-vis/dist/style.css';
import { IndexDataPoint } from '../reducers/weather';

export type ChartData = { x: number; y: number }[];

type WeatherChartProps = {
  data: IndexDataPoint[];
  topPadding?: number;
  yAxis?: string;
};

export default function WeatherChart(props: WeatherChartProps) {
  const { topPadding = 0, data: weatherData, yAxis = '' } = props;
  const data = weatherData.map((v) => ({ x: moment(v.date, 'YYYY-MM-DD').unix(), y: v.value }));
  const getTickValues = () => {
    const getYear = (date: string) => moment(date, 'YYYY-MM-DD').year();
    const startYear = getYear(weatherData[0].date);
    const endYear = getYear(weatherData[weatherData.length - 1].date);
    const yearTicks = Array.from({ length: endYear - startYear + 1 }, (v, k) => {
      const year = k + startYear;
      return moment(`${year}-01-01`, 'YYYY-MM-DD').unix();
    });
    return yearTicks;
  };

  const yMin = 0;
  const yMax = Math.max(...data.map((e) => e.y)) + topPadding;
  return (
    <FlexibleWidthXYPlot height={250} yDomain={[yMin, yMax]} colorType="literal">
      <XAxis tickValues={getTickValues()} tickFormat={(tick) => moment.unix(tick).format('YYYY')} />
      <YAxis title={yAxis} />
      <HorizontalGridLines />
      <LineSeries color="#3BAE65" data={data} />
    </FlexibleWidthXYPlot>
  );
}
