import React from 'react';
import { Link } from 'react-router-dom';

export default function BackLink({ to }: { to: string }) {
  const style = {
    fontSize: 15,
    marginLeft: 32,
    color: '#254B00',
    textDecoration: 'none',
  };
  return (
    <Link style={style} to={to}>
      {'< '}Back
    </Link>
  );
}
