import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux-api-middleware';
import profileReducer from './profile';
import userReducer from './user';
import weatherReducer from './weather';

const reducer = {
  profile: profileReducer,
  user: userReducer,
  weather: weatherReducer,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
