import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import insurance from './insurance.png';
import Histogram from '../components/Histogram';
import { cchMockData, crwMockData, fddMockData, payoutMockData } from '../utils/mockData';
import PayoutTable from '../components/PayoutTable';
import PayoutChart from '../components/PayoutChart';
import BackLink from '../components/BackLink';

const useStyles = makeStyles((theme) => ({
  title: { marginLeft: theme.spacing(4), marginTop: theme.spacing(2) },
  icon: { maxHeight: 150 },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    flexGrow: 1,
  },
  savings: { color: '#254B00', fontWeight: 700 },
  card: {
    height: '100%',
    padding: theme.spacing(1),
  },
  cardContent: {
    height: '100%',
    textAlign: 'center',
  },
  darkWrapper: {
    backgroundColor: 'rgb(18, 20, 39)',
    color: 'white',
  },
  center: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  strike: {
    textDecoration: 'line-through',
  },
  summaryWrapper: {
    width: '100%',
    padding: theme.spacing(4),
  },
  paper: {
    height: '100%',
    padding: theme.spacing(2),
  },
  subtitle: {
    paddingTop: theme.spacing(2),
  },
}));

export default function SolutionsApp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BackLink to="/report" />
      <div className={classes.summaryWrapper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h1">
              Risk Protection Solutions
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" color="textSecondary">
                  Est. 20 Year Payouts
                </Typography>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                  *With CropAssure MaxProtect,
                  <br /> total payout received
                </Typography>
                <div className={classes.center}>
                  <Typography className={classes.savings} variant="h2">
                    $28.5M
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" color="textSecondary">
                  Est. 20 Year Savings
                </Typography>
                <Typography variant="caption" color="textSecondary" gutterBottom>
                  *With CropAssure MaxProtect,
                  <br /> payout received minus premiums
                </Typography>
                <div className={classes.center}>
                  <Typography className={classes.savings} variant="h2">
                    $726K
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  Highest Potential Risks
                </Typography>
                <div className={classes.center}>
                  <Typography variant="h3" component="p" gutterBottom>
                    Frost
                  </Typography>
                  <Typography variant="h3" component="p" gutterBottom>
                    Excess Rain
                  </Typography>
                  <Typography variant="h3" component="p" gutterBottom>
                    Heat Stress
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className={clsx(classes.summaryWrapper, classes.darkWrapper)}>
        <Grid container spacing={3}>
          <Grid className={classes.iconContainer} item xs={2}>
            <img className={classes.icon} src={insurance} alt="Icon" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h4" component="h2" gutterBottom>
              Insurance Solutions: CropAssure for Almonds
            </Typography>
            <Typography variant="h6" component="div">
              We have applied a few different insurance products below to your selected portfolio
              site to give a sample of what protection with CropAssure could look like for you.
              These quotes are indicative and could change depending on your specific set of farm
              sites and coverage periods.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h3">
                CropAssure Frost Standard
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                Coverage Period: Blooming
              </Typography>
              <Typography variant="h6" component="p">
                Est. Quote: $315,000
              </Typography>
              <Typography variant="h6" component="p">
                MIN Payout: $2,362,500 @ 2 FDD
              </Typography>
              <Typography variant="h6" component="p">
                MAX Payout: $6,300,000 @ 10 FDD
              </Typography>
              <Histogram
                data={fddMockData.slice(20)}
                lowerThreshold={2}
                upperThreshold={10}
                yAxis="FDD"
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h3">
                CropAssure XSRain Max
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                Coverage Period: Blooming
              </Typography>
              <Typography variant="h6" component="p">
                Est. Quote: $275,000
              </Typography>
              <Typography variant="h6" component="p">
                MIN Payout: $1,890,000 @ {'>'}125mm in 5-day window
              </Typography>
              <Typography variant="h6" component="p">
                MAX Payout: $5,512,500 @ {'>'}150mm in 5-day wind.
              </Typography>
              <Histogram data={crwMockData} lowerThreshold={125} upperThreshold={150} yAxis="mm" />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h3">
                CropAssure ChillConfirm
              </Typography>
              <Typography variant="subtitle1" component="p" gutterBottom>
                Coverage Period: Dormancy
              </Typography>
              <Typography variant="h6" component="p" gutterBottom>
                Est. Quote: $205,750
              </Typography>
              <Typography variant="h6" component="p">
                MIN Payout: $1,102,500 @ {'<'}200 chilling hours
              </Typography>
              <Typography variant="h6" component="p">
                MAX Payout: $4,725,000 @ {'<'}150 chilling hours
              </Typography>
              <Histogram
                data={cchMockData.slice(20)}
                upperThreshold={150}
                lowerThreshold={200}
                triggeredBelow={true}
                yAxis="chill hours"
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div className={classes.summaryWrapper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" component="h2" gutterBottom>
              Our Recommended Protection: CropAssure MaxProtect for Almond
            </Typography>
            <div>
              <Typography variant="h5" component="span">
                Est. Premium: <span className={classes.strike}>$795,750</span>
              </Typography>
              <Typography variant="h4" component="span">
                $775,000
              </Typography>
              <Typography variant="subtitle1" component="span">
                {' '}
                (Save $20,750)
              </Typography>
            </div>
            <Typography variant="h6" component="div">
              If you were to receive the full insurance protection (all products listed above), here
              are the historical payouts you could have received:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <PayoutChart
              topPadding={500000}
              data={payoutMockData.map((pay) => ({ x: pay.year, y: pay.payout }))}
              yAxis="$"
            />
          </Grid>
          <Grid item xs={4}>
            <PayoutTable />
          </Grid>
        </Grid>
      </div>
      <div className={clsx(classes.summaryWrapper, classes.darkWrapper)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h4">
              Interested in learning more?
            </Typography>
            <Typography variant="h6">
              This is just a sample of the type of risk management protection available with our
              services. If you'd like to receive a full assessment of your portfolio's risks, you
              can work with a Lockton representative to get an in-depth analysis.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
