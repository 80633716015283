import React, { useState } from 'react';
import { Hint, LineSeries, VerticalBarSeries, XAxis, FlexibleWidthXYPlot, YAxis } from 'react-vis';
import '../../node_modules/react-vis/dist/style.css';
import { getRandomArbitrary } from '../utils/mockData';

export type ChartData = { x: number; y: number }[];

type HistogramProps = {
  triggeredBelow?: boolean;
  data: ChartData;
  upperThreshold?: number;
  yAxis?: string;
  lowerThreshold?: number;
  topPadding?: number;
  className?: any;
};

function getColor(value: number, triggeredBelow: boolean, lower?: number, upper?: number) {
  const NORMAL = '#254B00';
  const CROSSED = '#EB5E55';
  if (triggeredBelow === false) {
    if (lower && value > lower) return CROSSED;
    if (lower && value <= lower) return NORMAL;
  } else {
    if (lower && value < lower) return CROSSED;
    if (lower && value >= lower) return NORMAL;
  }
}

function getLabel(value: number, lower?: number) {
  if (lower && value > lower) return `$${getRandomArbitrary(20, 300)}`;
  return '';
}

const Histogram = (props: HistogramProps) => {
  const {
    data,
    upperThreshold,
    lowerThreshold,
    yAxis = '',
    triggeredBelow = false,
    topPadding = 0,
  } = props;

  const hydratedData = data.map((point) => ({
    ...point,
    color: getColor(point.y, triggeredBelow, lowerThreshold, upperThreshold),
    label: getLabel(point.y, lowerThreshold),
  }));
  const [animation, setAnimation] = useState(true);
  const [hoveredPoint, setHoveredPoint] = useState<{
    x: number | string;
    y: number;
  }>();

  const yMin = 0;
  const yMax = Math.max(...data.map((e) => e.y), upperThreshold ?? 0) + topPadding;
  return (
    <FlexibleWidthXYPlot yDomain={[yMin, yMax]} height={300} xType="ordinal" colorType="literal">
      <XAxis
        tickValues={[data[0], data[Math.floor(data.length / 2)], data[data.length - 1]].map(
          (record) => record.x,
        )}
      />
      <YAxis title={yAxis} />

      <VerticalBarSeries
        data={hydratedData}
        barWidth={0.6}
        animation={animation}
        onValueMouseOver={(event) => {
          console.log('setting point!');
          setAnimation(false);
          setHoveredPoint({
            x: event.x,
            y: event.y,
          });
        }}
        onValueMouseOut={(event) => {
          setAnimation(true);
          setHoveredPoint(undefined);
        }}
      />
      {lowerThreshold && (
        <LineSeries
          key="lower"
          animation
          data={[
            { x: data[0].x, y: lowerThreshold },
            {
              x: data[data.length - 1].x,
              y: lowerThreshold,
            },
          ]}
        />
      )}
      {upperThreshold && (
        <LineSeries
          key="upper"
          animation
          data={[
            { x: data[0].x, y: upperThreshold },
            {
              x: data[data.length - 1].x,
              y: upperThreshold,
            },
          ]}
        />
      )}

      {hoveredPoint && (
        <Hint value={hoveredPoint}>
          <div style={{ background: 'black', padding: '4px' }}>
            <p>
              {hoveredPoint.y} {yAxis}
            </p>
          </div>
        </Hint>
      )}
    </FlexibleWidthXYPlot>
  );
};

export default Histogram;
