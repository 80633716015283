import React from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';

import LocationOnIcon from '@material-ui/icons/LocationOn';

import { MAPBOX_TOKEN } from '../env';

type MapProps = {
  lat: number;
  lng: number;
  onClick?: (event: any) => void;
  width?: number;
  height?: number;
};

export default function SiteMap(props: MapProps) {
  const { lat, lng, onClick, width = 850, height = 400 } = props;
  const popup = (
    <Marker latitude={lat} longitude={lng}>
      <LocationOnIcon style={{ color: 'white' }} />
    </Marker>
  );
  return (
    <ReactMapGL
      key="default-map"
      mapboxApiAccessToken={MAPBOX_TOKEN}
      mapStyle="mapbox://styles/mapbox/satellite-v9"
      onClick={onClick}
      zoom={14}
      latitude={lat}
      longitude={lng}
      maxZoom={15}
      width={width}
      height={height}
    >
      {popup}
    </ReactMapGL>
  );
}
