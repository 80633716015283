import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';

import AmountTextField from '../components/AmountTextField';
import SiteMap from '../components/SiteMap';
import { Profile, setProfile } from '../reducers/profile';
import { RootState } from '../reducers';

const useStyles = makeStyles((theme) => ({
  title: { marginLeft: theme.spacing(4), marginTop: theme.spacing(2) },
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  map: { marginTop: theme.spacing(2) },
  actionWrapper: {
    margin: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  error: { color: theme.palette.error.main },
  button: { maxWidth: 450 },
  paper: {
    height: '100%',
    padding: theme.spacing(2),
    margin: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 350,
  },
  summaryWrapper: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

export default function SetupApp() {
  const profileData = useSelector((state: RootState) => state.profile);
  const profile: Profile | undefined = profileData !== {} ? (profileData as Profile) : undefined;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errorText, setError] = useState<string>();
  const [company, setCompany] = useState<string>(
    profile && profile.name ? profile.name : 'Acme Agro',
  );
  const [anchorValue, setAnchorValue] = useState<number>(
    profile && profile.anchorValue ? profile.anchorValue : 1000000,
  );
  const [
    location,
    // setLocation,
  ] = useState(
    profile && profile.site
      ? profile.site
      : {
          name: 'Durham, CA',
          lat: 39.6400929981007,
          lng: -121.81929959978,
        },
  );

  const [startDate, setStartDate] = useState(
    profile && profile.period ? moment(profile.period.start) : moment('2020-02-08'),
  );
  const [endDate, setEndDate] = useState(
    profile && profile.period ? moment(profile.period.end) : moment('2020-03-10'),
  );
  const [submitted, setSubmitted] = useState(false);

  const submitSetup = () => {
    setError('');
    const emptyString = (str: string) => str == null || str === '';
    const emptyNumber = (num: number) => num == null || isNaN(num);
    const emptyDate = (date: moment.Moment) => date == null || !date.isValid();
    if (emptyString(company)) {
      setError('Missing Company Name');
      return;
    } else if (emptyNumber(anchorValue)) {
      setError('Missing Anchor Value');
      return;
    } else if (
      emptyString(location.name) ||
      emptyNumber(location.lat) ||
      emptyNumber(location.lng)
    ) {
      setError('Invalid Site');
      return;
    } else if (emptyDate(startDate) || emptyDate(endDate)) {
      setError('Invalid Coverage Period');
      return;
    }
    const profile: Profile = {
      name: company,
      anchorValue,
      site: location,
      period: {
        start: startDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD'),
      },
    };
    console.log('profile', profile);
    dispatch(setProfile(profile));
    setSubmitted(true);
  };

  return (
    <div className={classes.root}>
      {submitted === true && <Redirect to="/report" />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.title} gutterBottom={false} variant="h3" component="h1">
            Setup Your Portfolio
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Basic Information</Typography>
            <TextField
              className={classes.textField}
              value={company}
              onChange={(ev) => setCompany(ev.target.value)}
              label="Company Name"
              variant="outlined"
            />
            <br />
            <AmountTextField
              label="Anchor Value"
              value={anchorValue}
              className={classes.textField}
              variant="outlined"
              onChange={(ev) => setAnchorValue(parseInt(ev.target.value, 10))}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Site Location</Typography>
            <TextField
              className={classes.textField}
              value={location.name}
              label="Name"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={classes.textField}
              value={location.lat.toFixed(5)}
              label="Latitude"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={classes.textField}
              value={location.lng.toFixed(5)}
              label="Longitude"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />

            <div className={classes.map}>
              <SiteMap lat={location.lat} lng={location.lng} />
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Coverage Period</Typography>
            <DatePicker
              label="Start Date"
              className={classes.textField}
              onChange={(date) => date && !Array.isArray(date) && setStartDate(date)}
              value={startDate}
            />
            <br />
            <DatePicker
              label="End Date"
              className={classes.textField}
              value={endDate}
              onChange={(date) => date && !Array.isArray(date) && setEndDate(date)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.actionWrapper}>
            {errorText && (
              <Typography className={classes.error} variant="h6">
                Error: Missing Anchor Value
              </Typography>
            )}
            <Button
              className={classes.button}
              onClick={submitSetup}
              variant="contained"
              color="primary"
            >
              <Typography variant="h5" component="span">
                Next: Risk Insights Report
              </Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
