import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import logo from '../logo_icon.svg';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    //backgroundColor: 'honeydew',
    position: 'absolute',
  },
  loader: {
    width: 150,
    height: 150,
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginLeft: '-75px',
    marginTop: '-75px',
    animation: `$loaderSpin 10s infinite`,
    transformOrigin: '50% 50%',
  },
  container: {
    textAlign: 'center',
  },
  containerLoader: {
    animation: `$loaderSpin 10s infinite`,
    width: 75,
  },
  '@keyframes loaderSpin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(-360deg)',
    },
  },
});

type LoaderProps = {
  isFullScreen?: boolean;
};

export default function Loader(props: LoaderProps) {
  const { isFullScreen = true } = props;
  const classes = useStyles();
  if (isFullScreen !== true) {
    return (
      <div className={classes.container}>
        <img src={logo} className={classes.containerLoader} alt="Loader Logo" />
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <img src={logo} className={classes.loader} alt="Loader Logo" />
      </div>
    );
  }
}
