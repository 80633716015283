import React, {useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ImpactEventChart from '../components/ImpactEventChart';
import CustomerCard from '../components/CustomerCard';
import Loader from '../components/Loader';

import {cchMockData} from '../utils/mockData';
import BackLink from '../components/BackLink';
import WeatherChart from '../components/WeatherChart';
import SiteMap from '../components/SiteMap';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../reducers';
import {Profile} from '../reducers/profile';
import {getToken} from '../reducers/user';
import {isEmpty} from '../utils';
import {fetchWeatherData, getIndexData, getWeatherData, isLoadingData} from '../reducers/weather';

const useStyles = makeStyles((theme) => ({
    title: {fontSize: 40, marginTop: 20, marginBottom: 0},
    button: {margin: 'auto'},
    actionWrapper: {display: 'flex'},
    root: {
        flexGrow: 1,
    },
    weatherImg: {width: '100%'},
    loader: {
        minHeight: '100vh',
        overflow: 'auto',
    },
    paper: {
        color: theme.palette.text.secondary,
        minHeight: 400,
        height: '100%',
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    cardHeader: {
        fontSize: 30,
        padding: theme.spacing(1),
    },
    cardSubheader: {
        fontSize: 20,
        paddingLeft: theme.spacing(1),
    },
    prediction: {
        padding: 5,
        fontSize: 18,
    },
    darkWrapper: {
        backgroundColor: 'rgb(18, 20, 39)',
        color: 'white',
    },
    summaryWrapper: {
        width: '100%',
        padding: theme.spacing(4),
    },
    summary: {
        fontSize: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
}));

// const tempSeries = Array(140)
//   .fill(0)
//   .reduce((arr: number[]) => [...arr, ...generateSeries(70, 5)], [])
//   .map((yVal: number, idx) => {
//     const initial = moment('2001-01-01', 'YYYY-MM-DD');
//     const dekad = 10;
//     const xVal = initial.add(dekad * idx, 'days').format('YYYY-MM-DD');
//     return { x: xVal, y: yVal };
//   });

// const rainSeries = Array(70)
//   .fill(0)
//   .reduce((arr: number[]) => [...arr, ...generateSeries(500, 10)], [])
//   .map((yVal: number, idx) => {
//     const initial = moment('2001-01-01', 'YYYY-MM-DD');
//     const dekad = 10;
//     const xVal = initial.add(dekad * idx, 'days').format('YYYY-MM-DD');
//     return { x: xVal, y: yVal };
//   });

export default function RiskProfile() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const profile = useSelector((state: RootState) => state.profile);
    const indexData = useSelector(getIndexData);
    const weatherData = useSelector(getWeatherData);
    const isLoadingIndexData = useSelector(isLoadingData);
    const token = useSelector(getToken);

    useEffect(() => {
        if (isEmpty(profile)) return;

        const payload = {
            first_season_year: 2001,
            last_season_year: 2019,
            period: {
                period_length: 60,
                start: {
                    month: 2,
                    day: 14,
                },
            },
            latitude: (profile as Profile).site.lat,
            longitude: (profile as Profile).site.lng,
            product_code: 'ALMD-USA-05-XS01',
            data: ['cdd', 'crw'],
            settled_only: true,
            raw_data: true,
        };
        dispatch(fetchWeatherData(token, payload));
    }, [dispatch, token, profile]);

    // handle empty profile data
    if (isEmpty(profile)) {
        return <Redirect to="/setup"/>;
    }
    const fullProfile = profile as Profile;
    const {site} = fullProfile;

    if (isLoadingIndexData || isEmpty(indexData)) {
        return (
            <div className={classes.loader}>
                <Loader/>;
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <BackLink to="/setup"/>
            <div className={classes.summaryWrapper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.title}>Your Risk Profile</h1>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CustomerCard profile={fullProfile}/>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper className={clsx(classes.paper, classes.summary)}>
                            <SiteMap lat={site.lat} lng={site.lng}/>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className={clsx(classes.summaryWrapper, classes.darkWrapper)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.title}>Historical Weather</h1>
                        <Typography variant="h6" component="div">
                            These are the raw weather values for your farm location. On their own, it can be
                            difficult to see the anomolies , but using our proprietary risk engine we will apply
                            these values below to identify past impact events on your crops.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography gutterBottom={false} variant="h4">
                            Cumulative Rainfall by Dekad
                        </Typography>
                        <Typography variant="h6" component="p">
                            mm, 2001-2020, with trendline
                        </Typography>
                        <Paper>
                            <WeatherChart data={weatherData.rainfall!.data}/>
                        </Paper>
                    </Grid>
                    {weatherData?.temperature?.data && <Grid item xs={12}>
                        <Typography gutterBottom={false} variant="h4">
                            Cumulative Temperature by Dekad
                        </Typography>
                        <Typography variant="h6" component="p">
                            degrees celcius, 2001-2020, with trendline
                        </Typography>
                        <Paper>
                            <WeatherChart data={weatherData.temperature!.data}/>
                        </Paper>
                    </Grid>}
                </Grid>
            </div>
            <div className={classes.summaryWrapper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.title}>Past Impact Events</h1>
                        <Typography variant="h6" component="div">
                            In this section, we have applied various risk indices to identify potential areas of
                            risk for your farm in previous years. Although these are indicative based on our own
                            data analysis, it is important to compare with your own yield information to determine
                            exactly which areas are of highest concern for your operation.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper className={classes.paper}>
                            <h3 className={classes.cardHeader}>Drought Impact Events</h3>
                            <h4 className={classes.cardSubheader}>
                                Consecutive Dry Days ({'<'}2.5mm rain), with risk threshold markers
                            </h4>
                            <ImpactEventChart riskData={indexData.cdd} criticalLevel={50} mildLevel={35}/>
                            <div className={classes.prediction}>
                                <p>
                                    Our pricing engine predicts that weather risk events affecting yield may have
                                    occurred in:
                                </p>
                                <ul>
                                    <li>2012 (critical)</li>
                                    <li>2019 (critical)</li>
                                </ul>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Paper className={classes.paper}>
                            <h3 className={classes.cardHeader}>Excess Rain Impact Events</h3>
                            <h4 className={classes.cardSubheader}>
                                Cumulative Rain in mm over 5-Day Moving Window, with risk threshold markers
                            </h4>
                            <ImpactEventChart riskData={indexData.crw} criticalLevel={150} mildLevel={125}/>
                            <div className={classes.prediction}>
                                <p>
                                    Our pricing engine predicts that weather risk events affecting yield may have
                                    occurred in:
                                </p>
                                <ul>
                                    <li>2005 (mild)</li>
                                    <li>2009 (critical)</li>
                                </ul>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    { indexData?.fdd?.length && <Grid item xs={12} lg={6}>
                        <Paper className={classes.paper}>
                            <h3 className={classes.cardHeader}>Frost Impact Events</h3>
                            <h4 className={classes.cardSubheader}>
                                Frost Degree Days, with risk threshold markers
                            </h4>

                            <ImpactEventChart
                                riskData={indexData.fdd}
                                criticalLevel={10}
                                mildLevel={3}
                                topPadding={5}
                            />
                            <div className={classes.prediction}>
                                <p>
                                    Our pricing engine predicts that weather risk events affecting yield may have
                                    occurred in:
                                </p>
                                <ul>
                                    <li>1989 (mild)</li>
                                    <li>1990 (critical)</li>
                                    <li>2011 (mild)</li>
                                    <li>2018 (mild)</li>
                                    <li>2019 (critical)</li>
                                </ul>
                            </div>
                        </Paper>
                    </Grid>}
                    <Grid item xs={12} lg={6}>
                        <Paper className={classes.paper}>
                            <h3 className={classes.cardHeader}>Heat Stress Impact Events</h3>
                            <h4 className={classes.cardSubheader}>
                                Cumulative Chilling Hours, with risk threshold markers
                            </h4>
                            <ImpactEventChart
                                mockData={cchMockData}
                                criticalLevel={150}
                                mildLevel={200}
                                impactAboveThreshold={false}
                            />
                            <div className={classes.prediction}>
                                <p>
                                    Our pricing engine predicts that weather risk events affecting yield may have
                                    occurred in:
                                </p>
                                <ul>
                                    <li>2003 (mild)</li>
                                    <li>2015 (mild)</li>
                                </ul>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className={clsx(classes.summaryWrapper, classes.actionWrapper)}>
                <Button
                    className={classes.button}
                    component={Link}
                    to="/solutions"
                    variant="contained"
                    color="primary"
                >
                    <Typography variant="h5" component="span">
                        Next: Solutions
                    </Typography>
                </Button>
            </div>
        </div>
    );
}
