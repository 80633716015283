import React, { useState, useEffect } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';

// import './App.css';
// import './styles/main.css';

import SetupApp from './containers/Setup';
import RiskProfileApp from './containers/RiskProfile';
import SolutionsApp from './containers/Solutions';
import Header from './components/Header';
import ReportStepper from './components/ReportStepper';
import LoginPage from './containers/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated, logout as logoutFn } from './reducers/user';
import PrivateRoute from './components/PrivateRoute';

const App = () => {
  const isAuth = useSelector(isAuthenticated);
  const dispatch = useDispatch();
  const logout = () => dispatch(logoutFn());
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    const { pathname } = location;
    console.log('location', location);
    if (pathname === '/report') {
      setActiveStep(1);
    } else if (pathname === '/solutions') {
      setActiveStep(2);
    } else {
      setActiveStep(0);
    }
  }, [location]);
  return (
    <>
      <Header isAuthenticated={isAuth} logout={logout} />
      {isAuth && <ReportStepper activeStep={activeStep} />}
      <Switch>
        <PrivateRoute path="/setup" component={SetupApp} />
        <PrivateRoute path="/report" component={RiskProfileApp} />
        <PrivateRoute path="/solutions" component={SolutionsApp} />
        {isAuth ? <Redirect from="/" to="/setup" /> : null}
        {isAuth ? <Redirect from="/login" to="/setup" /> : null}
        <Route path="/login" component={LoginPage} />
        {isAuth === false ? <Redirect to="/login" /> : null}
      </Switch>
    </>
  );
};

export default App;
